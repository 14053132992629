import { createSlice } from '@reduxjs/toolkit'
import type {
  WhiteListByIdResponse,
  WhiteListsPriceRangeResponse,
  WhiteListsResponse,
} from '../../API/apiInterfaces'

interface InitialStateProps {
  whiteListById: WhiteListByIdResponse
  whiteLists: WhiteListsResponse[]
  whiteListsCount: number
  whiteListsPriceRange: WhiteListsPriceRangeResponse
  bestOfferWhiteList: WhiteListsResponse[]
  whiteListDataError: string | undefined
  whiteListBestOfferDataError: string | undefined
  whiteListsDataError: string | undefined
  whiteListsCountDataError: string | undefined
  whiteListsPriceRangeDataError: string | undefined
}

const initialState: InitialStateProps = {
  whiteListById: {
    id: '',
    project: {
      id: 0,
      slug: '',
      categories: [
        {
          id: 0,
          name: '',
        },
      ],
      launchpad: {
        id: 0,
        name: '',
      },
      network: {
        id: 0,
        name: '',
      },
      saleType: {
        id: 0,
        name: '',
      },
      name: '',
      ticker: '',
      allocation: {
        min: 0,
        max: 0,
      },
      saleAt: '',
      siteUrl: '',
      idoUrl: '',
      telegramUrl: '',
      twitterUrl: '',
      discordUrl: '',
      docsUrl: '',
      logoUrl: '',
      bannerUrl: '',
      softCap: 0,
      hardCap: 0,
      description: '',
    },
    seller: {
      fullName: '',
      username: '',
      avatarUrl: '',
      whitelistCount: 1,
    },
    price: 0,
    wallet: '',
    status: '',
    orderId: '',
    createdAt: '',
  },
  whiteLists: [],
  bestOfferWhiteList: [],
  whiteListsCount: 0,
  whiteListsPriceRange: { min: 0, max: 100 },
  whiteListDataError: undefined,
  whiteListBestOfferDataError: undefined,
  whiteListsDataError: undefined,
  whiteListsCountDataError: undefined,
  whiteListsPriceRangeDataError: undefined,
}

const whiteListSlice = createSlice({
  name: 'whiteListSlice',
  initialState,
  reducers: {
    getWhiteListsData: (state, { payload }) => {
      /* saga trigger */
    },
    getWhiteListsDataSuccess: (state, { payload }) => {
      const { data, initFetching } = payload

      if (!Array.isArray(data)) return

      state.whiteLists = initFetching ? data : state.whiteLists.concat(data)
    },
    getWhiteListsDataError: (state, { payload }) => {
      state.whiteListsDataError = payload
    },

    getWhiteListDataById: (state, { payload }) => {
      payload.wallet && (state.whiteListById = payload)
    },
    getBestOfferWhiteListData: (state, { payload }) => {
      Array.isArray(payload) && (state.bestOfferWhiteList = payload)
    },
    getWhiteListsCountData: (state, { payload }) => {
      state.whiteListsCount = payload.count
    },
    getWhiteListPriceRangeData: (state, { payload }) => {
      payload.min && (state.whiteListsPriceRange = payload)
    },
    errorGettingWhiteListData: (state, { payload }) => {
      state.whiteListDataError = payload
    },
    errorGettingWhiteListBestOfferData: (state, { payload }) => {
      state.whiteListBestOfferDataError = payload
    },
    errorGettingWhiteListsCountData: (state, { payload }) => {
      state.whiteListsCountDataError = payload
    },
    errorGettingWhiteListsPriceRangeData: (state, { payload }) => {
      state.whiteListsPriceRangeDataError = payload
    },
  },
})

export const {
  getWhiteListDataById,
  getWhiteListPriceRangeData,
  getWhiteListsCountData,
  getWhiteListsData,
  getWhiteListsDataSuccess,
  getWhiteListsDataError,
  getBestOfferWhiteListData,
  errorGettingWhiteListData,
  errorGettingWhiteListsCountData,
  errorGettingWhiteListBestOfferData,
  errorGettingWhiteListsPriceRangeData,
} = whiteListSlice.actions
export default whiteListSlice.reducer
